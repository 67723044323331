import React from "react";
import Videosection from "../Menhome/Videosection";
import Special from "../Menhome/Special";
import Contact from "../Menhome/Contact";
import Banner from "../Menhome/Banner";
import Offer from "../Menhome/Offer";
import Story from "../Menhome/Story";
import Specialdish from "../Menhome/Specialdish";
import Menusection from "../Menhome/Menusection";
import Whyus from "../Menhome/Whyus";
import Teem from "../Menhome/Teem";
import Reacntupdate from "../Menhome/Recntupdates";
import { Link } from "react-router-dom";
import sidebarlogo from "../../assets/images/logo-white.png";
import Feature from "../Menhome/Feature";
import Buffet from "../Menhome/Buffet";

function Alacarte() {
    return (
        <>
            <div className="menu-backdrop"> </div>

            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer">
                        <span className="far fa-close"></span>
                    </div>
                    <div className="logo-box">
                        <Link to="/" title="Party Richter Bala">
                            <img
                                src={sidebarlogo}
                                alt=""
                                title="Party Richter Bala"
                            />
                        </Link>
                    </div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li>
                                <Link to="/">Startseite</Link>
                            </li>
                            <li>
                                <Link to="/#ueber-uns">Über uns</Link>
                            </li>
                            <li>
                                <Link to="/buffet">Buffet</Link>
                            </li>
                            <li>
                                <Link to="/a-la-carte" target="_blank">
                                    Á la carte
                                </Link>
                            </li>
                            <li>
                                <Link to="/kontakt">Kontakt</Link>
                            </li>
                        </ul>
                    </div>

                    <h2>Adresse</h2>
                    <ul className="info">
                        <li>Bahnhofstraße 70, 42781 Haan</li>
                        <li>Büro 9:00 Uhr - 13:00 Uhr</li>
                        <li>
                            <Link to="mailto:info@party-richter-bala.de">
                                info@party-richter-bala.de
                            </Link>
                        </li>
                    </ul>
                    <div className="separator">
                        <span></span>
                    </div>
                    <div className="booking-info">
                        <div className="bk-title">Kontakt</div>
                        <div className="bk-no">
                            <Link to="tel:021293788003">02129 3788003</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section
                style={{
                    padding: "40vh 0",
                    margin: "0 auto",
                    textAlign: "center",
                }}
            >
                <div
                    className="links-box wow fadeInUp"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                >
                    <div
                        className="link"
                        style={{
                            marginTop: "30px",
                        }}
                    >
                        <Link
                            to="/assets/NovDez-24.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="theme-btn btn-style-two clearfix"
                        >
                            <span className="btn-wrap">
                                <span className="text-one">
                                    4. November - 1. Dezember
                                </span>
                                <span className="text-two">
                                    4. November - 1. Dezember
                                </span>
                            </span>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Alacarte;
